import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RequisicaoBug } from "../../core/model";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ReportarProblemaService {
  reportarBugUrl = `${environment.apiUrl}faleConosco`;

  constructor(private http: HttpClient) {}

  public reportarProblema(requisicao: RequisicaoBug): Observable<any> {
    return this.http.post(this.reportarBugUrl, requisicao);
  }
}
