/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from "@angular/common/http";
import { inject, Injectable, signal } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import {
  authCodeFlowConfig,
  authHeaders,
  oauthTokenUrlAb,
} from "./auth.config";
import { firstValueFrom } from "rxjs";
import { AbAuthResponse } from "./auth.interfaces";
import { NzMessageService } from "ng-zorro-antd/message";
import { TokenStore } from "./token.store";
import { Router } from "@angular/router";
import { LogoutService } from "./logout.service";
import { RefreshTokenService } from "./refresh-token.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // ############### Injections ###############
  private readonly http = inject(HttpClient);
  private readonly oauthService = inject(OAuthService);
  private readonly router = inject(Router);
  private readonly messageService = inject(NzMessageService);
  private readonly tokenStore = inject(TokenStore);
  private readonly logoutService = inject(LogoutService);
  private readonly refreshTokenService = inject(RefreshTokenService);

  // ############### State ###############
  public loading = signal(false);

  // ############### Constructor ###############
  constructor() {
    this.initializeOAuth();
    this.authenticate();
  }

  // ############### Methods ###############
  private initializeOAuth(): void {
    this.oauthService.configure(authCodeFlowConfig);
  }

  public initiateGovBrAuth() {
    this.oauthService.initCodeFlow();
  }

  public async authenticate(userClick: boolean = false) {
    this.loading.set(true);
    try {
      // tenta fazer o refresh com o cookie armazenado se houver
      await firstValueFrom(this.refreshTokenService.refreshToken())
        .then(() => {
          this.navigateToInitialRoute();
          return;
        })
        .catch((e) => {
          console.error("Erro ao realizar o refreshToken após login", e);
          this.tokenStore.clearToken();
        });

      // tenta carregar access token govbr se houver
      await this.oauthService.loadDiscoveryDocumentAndTryLogin();

      //verifica se o access token do govbr esta valido
      if (this.oauthService.hasValidAccessToken()) {
        // tenta fazer o login com o access token do govbr
        await this.loginAb();
      } else if (userClick) {
        // inicia novo processo de autenticacao no govbr
        this.initiateGovBrAuth();
      }
    } catch (error) {
      console.error("Authentication error:", error);
    } finally {
      this.loading.set(false);
    }
  }

  private async loginAb(): Promise<void> {
    const headers = authHeaders;
    const body = `username=${this.credentials?.usuario}&password=${this.credentials?.senha}&grant_type=password&idToken=${this.credentials?.idToken}`;

    try {
      await firstValueFrom(
        this.http.post<AbAuthResponse>(oauthTokenUrlAb, body, {
          headers,
          withCredentials: true,
        }),
      );
    } catch (error) {
      console.error("Erro durante a chamada HTTP:", error);
      this.messageService.error(
        "Falha na comunicação com o servidor. Por favor, tente novamente.",
      );
      this.logoutService.logoutCompleto();
      return;
    }

    try {
      await firstValueFrom(this.refreshTokenService.refreshToken());
      this.navigateToInitialRoute();
    } catch (error) {
      console.error("AuthService: Erro ao realizar o refresh do token:", error);
      this.messageService.error(
        "Falha ao atualizar as credenciais. Por favor, tente novamente.",
      );
      this.logoutService.logoutCompleto();
    }
  }

  // ############### Getters ###############
  get credentials() {
    return {
      usuario: encodeURIComponent(
        (this.oauthService.getIdentityClaims() as any)?.sub.trim() || "",
      ),
      senha: encodeURIComponent(
        JSON.stringify(this.oauthService.getAccessToken())
          .replace('"', "")
          .trim(),
      ),
      idToken: encodeURIComponent(
        JSON.stringify(this.oauthService.getIdentityClaims()),
      ),
    };
  }

  // ############### Helpers ###############
  navigateToInitialRoute() {
    const rotaInicial = this.tokenStore.allowedRoutes()[0]
      ? this.tokenStore.allowedRoutes()[0]
      : "/solicitar-acesso";
    this.router.navigate([rotaInicial]);
  }
}
