export interface MenuGroup {
  displayName: string;
  itens: ItemMenu[];
}

interface ItemMenu {
  displayName: string;
  disabled?: boolean;
  iconName: string;
  iconType: "nz-icon" | "ng-icon";
  route?: string;
  visible?: boolean;
  tooltip?: string;
  badge?: string;
}

export const initialState = {
  moduloAtivo: "",
  currentRoute: "",
  isCollapsed: true,
  hasUsuarioOrgao: false,
  govBrData: null as Record<string, any> | null,
  firstLoad: true,
};
