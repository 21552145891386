<nz-spin [nzSpinning]="salvando">
  <form
    nz-form
    nzLayout="vertical"
    [formGroup]="form"
    (ngSubmit)="submitForm()"
    class="text-gray-700"
  >
    <nz-form-item>
      <nz-form-label nzRequired>Título do problema</nz-form-label>
      <nz-form-control [nzErrorTip]="errorTitulo">
        <input
          nz-input
          formControlName="title"
          maxlength="75"
          placeholder="Ex: Problema com o sistema de login"
          type="text"
        />
        <ng-template #errorTitulo let-control>
          @if (control.hasError('required')) {
          <p>O título é obrigatório.</p>
          } @else if (control.hasError('minlength')) {
          <p>O título é muito curto.</p>
          }
          <!-- <pre>{{ control.errors | json }}</pre> -->
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzRequired>Descrição do problema</nz-form-label>
      <nz-form-control [nzErrorTip]="errorDescricao">
        <nz-textarea-count [nzMaxCharacterCount]="400">
          <textarea
            nz-input
            formControlName="description"
            rows="5"
            placeholder="Ex: O sistema de login não está funcionando corretamente"
          ></textarea>
        </nz-textarea-count>
        <ng-template #errorDescricao let-control>
          @if (control.hasError('required')) {
          <p class="-mt-5">A descrição é obrigatória.</p>
          } @else if (control.hasError('minlength')) {
          <p class="-mt-5">A descrição é muito curta.</p>
          }
          <!-- <pre>{{ control.errors | json }}</pre> -->
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="mb-2">
      <div class="flex items-center gap-3">
        <input
          id="image"
          type="file"
          accept="image/jpeg, image/png"
          (change)="onFileChange($event)"
          class="!hidden"
          #fileInput
        />
        <button nz-button type="button" (click)="fileInput.click()">
          <span nz-icon nzType="paper-clip" nzTheme="outline"></span>
          Anexar imagem
        </button>
        @if (!isImageValid) {
        <span class="text-sm text-red-500/90">
          Adicione uma imagem do problema informado.
        </span>
        }
        <span class="text-xs font-medium text-gray-700">{{
          imageFile?.name
        }}</span>
      </div>
    </nz-form-item>

    <div *nzModalFooter>
      <button nz-button (click)="closeModal()" [disabled]="salvando">
        Cancelar
      </button>
      <button
        nz-button
        nzType="primary"
        type="submit"
        [disabled]="salvando || form.invalid || !imageFile"
        [nz-tooltip]="
          !form.valid
            ? 'Preencha todos os campos antes de enviar'
            : !imageFile
            ? 'É necessário anexar uma imagem do problema'
            : null
        "
        (click)="submitForm()"
      >
        Enviar
      </button>
    </div>
  </form>
</nz-spin>
